const CustomerLayoutSettings = {
    leftSidebar: {
        open: true,
        secondaryNavOpen: false,
    },
    searchBox: {
        open: false,
    },
};

export default CustomerLayoutSettings;
