import { lazy } from "react";
import { authRoles } from "app/auth/authRoles";

const CreateTrips = lazy(() => import("./CreateTrips"));
const ActiveTrips = lazy(() => import("./ActiveTrips"));
const PendingTrips = lazy(() => import("./PendingTrips"));
const CompletedTrips = lazy(() => import("./CompletedTrips"));
const PrintActiveTrips = lazy(() => import("./PrintActiveTrips"));


//Customer
const CustomerDashboard = lazy(()=>import("../../CustomerDashboard/CustomerDashboard"));
const ArchievedShipments = lazy (()=>import ("../../CustomersComponents/ArchiveShipment"));
const DeliveredShipments = lazy (()=>import ("../../CustomersComponents/DeliveredShipment"));
const PendingShipments = lazy (()=>import ("../../CustomersComponents/PendingShipment"));
const ReturnedShipments = lazy (()=>import ("../../CustomersComponents/ReturnedShipment"));
const TransitShipments = lazy (()=>import ("../../CustomersComponents/TransitShipment"));


const schedulingRoutes = [
  {
    path: "/createtrips",
    component: CreateTrips,
    auth: authRoles.admin,
  },
  {
    path: "/printactivetrips",
    component: PrintActiveTrips,
    auth: authRoles.admin,
  },
  {
    path: "/activetrips",
    component: ActiveTrips,
    auth: authRoles.admin,
  },
  {
    path: "/pendingtrips",
    component: PendingTrips,
    auth: authRoles.admin,
  },
  {
    path: "/completedtrips",
    component: CompletedTrips,
    auth: authRoles.admin,
  },

  //Customers
  {
    path: "/dashboard/customer",
    component: CustomerDashboard,
    auth: authRoles.admin,
  },
  {
    path: "/customer/Archive",
    component: ArchievedShipments,
    auth: authRoles.admin,
  },
  {
    path: "/customer/Delivered",
    component: DeliveredShipments,
    auth: authRoles.admin,
  },
  {
    path: "/customer/Pending",
    component: PendingShipments,
    auth: authRoles.admin,
  },
  {
    path: "/customer/Returned",
    component: ReturnedShipments,
    auth: authRoles.admin,
  },
  {
    path: "/customer/Transit",
    component: TransitShipments,
    auth: authRoles.admin,
  },

];

export default schedulingRoutes;
