import { lazy } from "react";
// import { authRoles } from "app/auth/authRoles";

const CompleteDriverTrip = lazy(() => import("./CompleteDriverTrip"));
const ActivateDriverTrip =lazy(() => import("./ActivateDriverTrip"));
const ReceiveTransportedBagDomestic =lazy(() => import("./ReceiveTransportedBagDomestic"));
const ReceiveInternationalConsignment =lazy(() => import("./ReceiveInternationalConsignment"));
const CreateTransportedBagDomestic =lazy(() => import("./CreateTransportedBagDomestic"));
const CreateInternationalConsignment =lazy(() => import("./CreateInternationalConsignment"));

const terminalRoutes = [
    {
        path: "/CompleteDriverTrip",
        component: CompleteDriverTrip,

    },
    {
        path: "/ActivateDriverTrip",
        component: ActivateDriverTrip,

    },
    {
        path: "/ReceiveTransportedBagDomestic",
        component: ReceiveTransportedBagDomestic,

    },
    {
        path: "/ReceiveInternationalConsignment",
        component: ReceiveInternationalConsignment,

    },
    {
        path: "/CreateTransportedBagDomestic",
        component: CreateTransportedBagDomestic,

    },
    {
        path: "/CreateInternationalConsignment",
        component: CreateInternationalConsignment,

    },

]
export default terminalRoutes;