import axios from "axios";
import localStorageService from "./localStorageService";
import History from "@history";

class JwtAuthService {
  user = {
    id: null,
    role: null,
    userType: null,
    userName: null,
    password: null,
    typeId: null,
    token: null,
  };
  constructor() {
    const preUser = localStorageService.getItem("user");
    if (preUser) {
      this.setSession(preUser.sessionKey);
      this.setUser(preUser);
      return;
    }
    console.log(preUser);
  }
  loginWithEmailAndPassword = (userName, password) => {
    const data = {
      userName,
      password,
    };

    console.log("loginWithEmailAndPassword");
    console.log(userName, password);
    console.log(this.user);

    return axios
      .post(process.env.REACT_APP_BASE_URL + "/user/login", data)
      .then(async (res) => {
        console.log(res);

        if (res.data.message === "Valid Credentials") {
          console.log("Credentials are valid");

          // Set dynamic values from API response
          this.user.id = res.data.data.id;
          this.user.role = res.data.data.role;
          this.user.userType = res.data.data.userType;
          this.user.userName = res.data.data.userName;
          this.user.password = res.data.data.password;
          this.user.typeId = res.data.data.typeId;
          this.user.token = res.data.data.sessionKey;

          // Set items in local storage
          localStorage.setItem("user", JSON.stringify(res.data.data));

          // Navigate to conditional routes
          if (res.data.data.role === "ADMIN") {
            History.push("/dashboard/v1");
          } else if (res.data.data.role === "CUST-BIZ") {
            History.push("/dashboard/shipper");
          }

          return new Promise((resolve, reject) => {
            setTimeout(() => {
              resolve(this.user);
            }, 1000);
          }).then((data) => {
            console.log("Setting Data");
            this.setSession(data.token);
            this.setUser(data);
            return data;
          });
        } else {
          alert("Invalid Credentials");
          return Promise.reject("Invalid Credentials");
        }
      })
      .catch((err) => {
        console.error(err);
        return Promise.reject(err);
      });
  };

  loginWithToken = () => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(this.user);
      }, 100);
    }).then((data) => {
      this.setSession(data.token);
      this.setUser(data);
      return data;
    });
  };

  logout = () => {
    this.setSession(null);
    this.removeUser();
  };

  setSession = (token) => {
    if (token) {
      localStorage.setItem("jwt_token", token);
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    } else {
      localStorage.removeItem("jwt_token");
      delete axios.defaults.headers.common["Authorization"];
    }
  };

  setUser = (user) => {
    localStorageService.setItem("auth_user", user);
  };

  removeUser = () => {
    localStorage.removeItem("auth_user");
    localStorage.removeItem("user");
  };
}

export default new JwtAuthService();

// import axios from "axios";
// import localStorageService from "./localStorageService";
// import History from "@history";
// import ViewCustomerWareHouse from "app/views/Locations/Customer-Warehouse/ViewCustomerWareHouse";
// class JwtAuthService {
//   user = {
//     token: "faslkhfh423oiu4h4kj432rkj23h432u49ufjaklj423h4jkhkjh",
//     userName: "abcd",
//     password: "abcd",
//     role: "ADMIN",
//     userId: "1",
//   };

//   loginWithEmailAndPassword = (userName, password) => {
//     const data = {
//       userName,
//       password,
//     };

//     console.log("loginwithemailandpassword");
//     console.log(userName, password);
//     console.log(this.user);
//     axios
//       .post(process.env.REACT_APP_BASE_URL + "/user/login", data)
//       .then(async (res) => {
//         console.log(res);

//         if (res.data.message === "Valid Credentials") {
//           console.log("credentials are valid");
//           this.user.role = res.data.data.role;
//           this.user.userId = res.data.data.id;
//           this.user.userName = res.data.data.userName;
//           this.user.password = res.data.data.password;
//           if (res.role === "ADMIN") {
//             History.push({
//               pathname: "/dashboard/v1",
//             });
//           } else if ("role === CUST-BIZ") {
//             History.push({
//               pathname: "/dashboard/shipper",
//             });
//           }
//           localStorage.setItem("user", JSON.stringify(res.data.data));
//           console.log(JSON.parse(localStorage.user));
//           const sk = res.data.data.sessionKey;
//           console.log(sk);

//           return new Promise((resolve, reject) => {
//             <ViewCustomerWareHouse sk={ssk} /> // error hereExpected an assignment or function call and instead saw an expression.eslintno-unused-expressions 'React' must be in scope when using JSXeslintreact/react-in-jsx-scope

//             setTimeout(() => {
//               resolve(this.user);
//             }, 1000);
//           }).then((data) => {
//             console.log("Setting Data");
//             this.setSession(data);
//             this.setUser(data);
//             return data;
//           });
//         } else {
//           alert("Invalid Credentials");
//         }
//       })
//       .catch((err) => {
//         console.log(err);
//       });
//   };

//   loginWithToken = () => {
//     return new Promise((resolve, reject) => {
//       setTimeout(() => {
//         resolve(this.user);
//       }, 100);
//     }).then((data) => {
//       this.setSession(data.token);
//       this.setUser(data);
//       return data;
//     });
//   };

//   logout = () => {
//     this.setSession(null);
//     this.removeUser();
//   };

//   setSession = (token) => {
//     if (token) {
//       localStorage.setItem("jwt_token", token);
//       axios.defaults.headers.common["Authorization"] = "Bearer " + token;
//     } else {
//       localStorage.removeItem("jwt_token");
//       delete axios.defaults.headers.common["Authorization"];
//     }
//   };
//   setUser = (user) => {
//     localStorageService.setItem("auth_user", user);
//   };
//   removeUser = () => {
//     localStorage.removeItem("auth_user");
//     localStorage.removeItem("user");
//   };
// }

// export default new JwtAuthService();
