import {lazy} from "react";
import { authRoles } from "app/auth/authRoles"

const ArchiveShipment = lazy(()=>import("./ArchiveShipment"));
const DeliveredShipment = lazy(()=>import("./DeliveredShipment"));
const PendingShipment = lazy(()=>import("./PendingShipment"));
const ReturnedShipment = lazy(()=>import("./ReturnedShipment"));
const TransitShipment = lazy(()=>import("./TransitShipment"));

const CustomerComponentsRoutes=[
    {
        path:"/customer/Archive",
        component: ArchiveShipment ,
        auth:authRoles.admin,
    },
    {
        path:"/customer/Delivered",
        component: DeliveredShipment ,
        auth:authRoles.admin,
    },
    {
        path:"/customer/Pending",
        component: PendingShipment ,
        auth:authRoles.admin,
    },
    {
        path:"/customer/Returned",
        component: ReturnedShipment ,
        auth:authRoles.admin,
    },
    {
        path:"/customer/Transit",
        component: TransitShipment ,
        auth:authRoles.admin,
    },
]

export default CustomerComponentsRoutes;