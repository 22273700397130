import { lazy } from "react";
import { authRoles } from "app/auth/authRoles";

const CustomerDashboard = lazy(() => import("./CustomerDashboard"));

const CustomerDashboardRoutes = [
    {
        path: "/dashboard/customer",
        component: CustomerDashboard,
        auth: authRoles.admin,
    },
];

export default CustomerDashboardRoutes;
