import { lazy } from "react";
import { authRoles } from "app/auth/authRoles";

const ViewWareHouse = lazy(() => import("./ViewWareHouse/ViewWareHouse"));
const AddWareHouse = lazy(() => import("./ViewWareHouse/AddWarehouse"));
const EditWareHouse = lazy(() => import("./ViewWareHouse/EditWareHouse"));
const StaffManagement = lazy(() => import("./WareHouseCrew/StaffManagement"));

const AddStaff = lazy(() => import("./WareHouseCrew/ViewStaff/AddStaff"));
const EditStaff = lazy(() => import("./WareHouseCrew/ViewStaff/EditStaff"));
const ViewStaff = lazy(() => import("./WareHouseCrew/ViewStaff/ViewStaff"));
const StaffMember = lazy(() => import("./WareHouseCrew/ViewStaff/StaffMemberDetails"));


const AddAssets = lazy(() => import("./WareHouseManagement/WareHouseInventory/AddAssets"));
const EditAssets = lazy(() => import("./WareHouseManagement/WareHouseInventory/EditAssets"));
const AssetsInventory = lazy(() => import("./WareHouseManagement/WareHouseInventory/AssetsInventory"));
const Allocation = lazy(() => import("./WareHouseManagement/WareHouseInventory/Allocation"));


const Shelves = lazy(() => import("./WareHouseManagement/WareHouseShelves"));
const Terminal = lazy(() => import("./WareHouseManagement/WareHouseTerminal"));


const wareHouseRoutes = [
    {
        path: "/ViewWareHouse",
        component: ViewWareHouse,
        auth: authRoles.admin
    },
    {
        path: "/ViewWareHouse/Add",
        component: AddWareHouse,
        auth: authRoles.admin
    },
    {
        path: "/ViewWareHouse/Edit",
        component: EditWareHouse,
        auth: authRoles.admin
    },
    {
        path: "/StaffManagement",
        component: StaffManagement,
        auth: authRoles.admin
    },

    {
        path: "/ViewStaff",
        component: ViewStaff,
        auth: authRoles.admin
    },
    {
        path: "/ViewStaff/Add",
        component: AddStaff,
        auth: authRoles.admin
    },
    {
        path: "/ViewStaff/Edit",
        component: EditStaff,
        auth: authRoles.admin
    },
    {
        path: "/StaffMember",
        component: StaffMember,
        auth: authRoles.admin
    },

    {
        path: "/AssetsInventory",
        component: AssetsInventory,
        auth: authRoles.admin
    },
    {
        path: "/AssetsInventory/Add",
        component: AddAssets,
        auth: authRoles.admin
    },
    {
        path: "/AssetsInventory/Edit",
        component: EditAssets,
        auth: authRoles.admin
    },
    {
        path: "/Allocation",
        component: Allocation,
        auth: authRoles.admin
    },

    {
        path: "/WareHouseShelves",
        component: Shelves,
        auth: authRoles.admin
    },
    {
        path: "/WareHouseTerminal",
        component: Terminal,
        auth: authRoles.admin
    },
]

export default wareHouseRoutes;