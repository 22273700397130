import { lazy } from "react";
import { authRoles } from "app/auth/authRoles";

const PendingPickup = lazy(() => import("./PendingPickup"));
const PendingPickupShipper = lazy(() => import("./PendingPickupShipper"));
const PendingPickupView = lazy(() => import("./PendingPickupView"));
const Receipt = lazy(() => import("./Receipt"));
const IntripShipment = lazy(() => import("./IntripShipment"));
const ShelfShipment = lazy(() => import("./ShelfShipment"));
const FloorShipment = lazy(() => import("./FloorShipment"));
const InterCityShipment = lazy(() => import("./InterCityShipment"));
const BulkCargoShipment = lazy(() => import("./BulkCargoShipment"));
const TransportedShipment = lazy(() => import("./TransportedShipment"));
const CancelledShipment = lazy(() => import("./CancelledShipment"));
const UndefineShipment = lazy(() => import("./UndefineShipment"));
const ReturnShipment = lazy(() => import("./ReturnShipment"));
const ShipmentReport = lazy(() => import("./ShipmentReport"));
const ArchievedShipments = lazy(() => import("./ArchievedShipments"));
const Archivedshipper = lazy(() => import("./Archivedshipper"));
const ShipmentInvoiceWay = lazy(() => import("./ShipmentInvoiceWay"));
const ShipmentsReceivedFromCustomers = lazy(() =>
  import("./ShipmentsReceivedFromCustomers")
);

//Terminals
const Terminal = lazy(() => import("../../Terminal/Terminal"));
const CompleteDriverTrip = lazy(() =>
  import("../../Terminal/CompleteDriverTrip")
);
const ActivateDriverTrip = lazy(() =>
  import("../../Terminal/ActivateDriverTrip")
);
const ReceiveTransportedBagDomestic = lazy(() =>
  import("../../Terminal/ReceiveTransportedBagDomestic")
);
const ReceiveInternationalConsignment = lazy(() =>
  import("../../Terminal/ReceiveInternationalConsignment")
);
const CreateTransportedBagDomestic = lazy(() =>
  import("../../Terminal/CreateTransportedBagDomestic")
);
const CreateInternationalConsignment = lazy(() =>
  import("../../Terminal/CreateInternationalConsignment")
);
const GetTrips = lazy(() => import("../../Terminal/GetTrips"));

const shipmentviewRoutes = [
  {
    path: "/shipmentviews/pendingpickup",
    component: PendingPickup,
    auth: authRoles.cusBiz,
  },
  {
    path: "/shipmentviews/pendingpickupshipper",
    component: PendingPickupShipper,
    auth: authRoles.admin,
  },
  {
    path: "/pendingpickup/pendingpickupview/:id",
    component: PendingPickupView,
    auth: authRoles.cusBiz,
  },
  {
    path: "/pendingpickup/receipt/:id",
    component: Receipt,
    auth: authRoles.cusBiz,
  },
  {
    path: "/shipmentviews/intripshipment",
    component: IntripShipment,
    auth: authRoles.admin,
  },
  {
    path: "/shipmentviews/shelfshipment",
    component: ShelfShipment,
    auth: authRoles.admin,
  },
  {
    path: "/shipmentviews/floorshipment",
    component: FloorShipment,
    auth: authRoles.admin,
  },
  {
    path: "/shipmentviews/intercityshipment",
    component: InterCityShipment,
    auth: authRoles.admin,
  },
  {
    path: "/shipmentviews/bulkcargoshipment",
    component: BulkCargoShipment,
    auth: authRoles.admin,
  },
  {
    path: "/shipmentviews/transportedshipment",
    component: TransportedShipment,
    auth: authRoles.admin,
  },
  {
    path: "/shipmentviews/cancelledshipment",
    component: CancelledShipment,
    auth: authRoles.admin,
  },
  {
    path: "/shipmentviews/undefineshipment",
    component: UndefineShipment,
    auth: authRoles.admin,
  },
  {
    path: "/shipmentviews/returnshipment",
    component: ReturnShipment,
    auth: authRoles.admin,
  },
  {
    path: "/shipmentviews/shipmentreport",
    component: ShipmentReport,
    auth: authRoles.admin,
  },
  {
    path: "/shipmentviews/archievedshipments",
    component: ArchievedShipments,
    auth: authRoles.admin,
  },
  {
    path: "/shipmentviews/archivedshipments",
    component: Archivedshipper,
    auth: authRoles.admin,
  },
  {
    path: "/shipment/invoice-and-waybill/:id",
    component: ShipmentInvoiceWay,
    auth: authRoles.admin,
  },
  //terminals
  {
    path: "/terminal",
    component: Terminal,
    auth: authRoles.admin,
  },
  {
    path: "/CompleteDriverTrip",
    component: CompleteDriverTrip,
    auth: authRoles.admin,
  },
  {
    path: "/ActivateDriverTrip",
    component: ActivateDriverTrip,
    auth: authRoles.admin,
  },
  {
    path: "/ReceiveTransportedBagDomestic",
    component: ReceiveTransportedBagDomestic,
    auth: authRoles.admin,
  },
  {
    path: "/ReceiveInternationalConsignment",
    component: ReceiveInternationalConsignment,
    auth: authRoles.admin,
  },
  {
    path: "/CreateTransportedBagDomestic",
    component: CreateTransportedBagDomestic,
    auth: authRoles.admin,
  },
  {
    path: "/CreateInternationalConsignment",
    component: CreateInternationalConsignment,
    auth: authRoles.admin,
  },
  {
    path: "/get-trips",
    component: GetTrips,
    auth: authRoles.admin,
  },
  {
    path: "/shipments/pending-customer-shipments",
    component: ShipmentsReceivedFromCustomers,
    auth: authRoles.admin,
  },
];

export default shipmentviewRoutes;
